import { useTranslation } from '@pancakeswap/localization'
import { Flex, Heading, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { formatNumber } from '@pancakeswap/utils/formatBalance'
import { styled } from 'styled-components'
import { getCakeVaultAddress } from 'utils/addressHelpers'

const StyledColumn = styled(Flex)<{ noMobileBorder?: boolean; noDesktopBorder?: boolean }>`
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border: 3px solid #b8b8b8;
  border-bottom: none;
  border-radius: 15px;
  width: 33%;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 33%;
  }

  // ${({ theme }) => theme.mediaQueries.lg} {
  //   width: auto;
  //   &:not(:last-child) {
  //     border-right: 1px solid ${({ theme }) => theme.colors.cardBorder};
  //   }
  // }
`
const StyledWrapper = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`

const StyledText = styled(Text)`
  margin-top: 24px;
  margin-bottom: 24px;
  ${({ theme }) => theme.mediaQueries.lg} {
    margin-top: 50px;
  }
`

/**
 * User (Planet Finance) built a contract on top of our original manual CAKE pool,
 * but the contract was written in such a way that when we performed the migration from Masterchef v1 to v2, the tokens were stuck.
 * These stuck tokens are forever gone (see their medium post) and can be considered out of circulation."
 * https://planetfinanceio.medium.com/pancakeswap-works-with-planet-to-help-cake-holders-f0d253b435af
 * https://twitter.com/PancakeSwap/status/1523913527626702849
 * https://bscscan.com/tx/0xd5ffea4d9925d2f79249a4ce05efd4459ed179152ea5072a2df73cd4b9e88ba7
 */
const planetFinanceBurnedTokensWei = 637407922445268000000000n
const cakeVaultAddress = getCakeVaultAddress()

const FunnEarnDataRow = () => {
  const { t } = useTranslation()

  const { isMobile } = useMatchBreakpoints()

  return (
    <>
      <StyledText
        color="#ffffff"
        maxWidth={600}
        fontSize={['20px', '20px', null, '24px']}
        textAlign={isMobile ? 'center' : 'left'}
        lineHeight="110%"
        fontWeight={600}
      >
        {t('Beta Launch In Numbers')}
      </StyledText>
      <StyledWrapper mb={isMobile ? '30px' : '50px'}>
        <StyledColumn>
          <Heading color="secondary" scale="lg">
            {t('%player%+', { player: formatNumber(2000000, 0, 0) })}
          </Heading>
          <Text color="text" bold fontSize={isMobile ? '14px' : undefined}>
            {t('Players')}
          </Text>
          {/* <Balance color="secondary" decimals={0} lineHeight="1.1" fontSize="24px" bold value={2000000} />+ */}
        </StyledColumn>
        <StyledColumn noMobileBorder>
          <Heading color="secondary" scale="lg">
            {t('%game%', { game: formatNumber(10, 0, 0) })}
          </Heading>
          <Text bold fontSize={isMobile ? '14px' : undefined}>
            {t('Connected Game')}
          </Text>
        </StyledColumn>
        <StyledColumn>
          <Heading color="secondary" scale="lg">
            {t('%tournament%+', { tournament: formatNumber(100, 0, 0) })}
          </Heading>
          <Text bold fontSize={isMobile ? '14px' : undefined}>
            {t('Game tournament')}
          </Text>
        </StyledColumn>
        <StyledColumn>
          <Heading color="secondary" scale="lg">
            {t('$ %usd%+', { usd: formatNumber(2000, 0, 0) })}
          </Heading>
          <Text bold fontSize={isMobile ? '14px' : undefined}>
            {t('Worth reward')}
          </Text>
        </StyledColumn>
      </StyledWrapper>
    </>
  )
}

export default FunnEarnDataRow
