import { useCallback, useLayoutEffect, useRef } from 'react'

export const useDrawCanvas = (
  videoRef: React.MutableRefObject<HTMLVideoElement | undefined> | React.RefObject<HTMLVideoElement>,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  intervalRef: React.MutableRefObject<number>,
  width: number,
  height: number,
  onVideoStartCallback?: () => void,
  onVideoVideoEnd?: () => void,
  additionalVideoRefs?: React.RefObject<HTMLVideoElement>[],
) => {
  let video = videoRef?.current
  let canvas = canvasRef?.current
  let isElementReady = video && canvas
  const isVideoPlaying = useRef(false)

  console.log('useDrawCanvas', video, canvas, isElementReady)
  const drawImage = useCallback(() => {
    isVideoPlaying.current = false
    const context = canvas?.getContext('2d')
    console.log('drawImage')

    if (!canvas || !video || !context) return
    context.clearRect(0, 0, width, height)
    context.drawImage(video, 0, 0, width, height)
    additionalVideoRefs?.forEach((ref) => {
      const additionalVideo = ref.current
      if (!additionalVideo) return
      context.drawImage(additionalVideo, 0, 0, width, height)
    })
    onVideoStartCallback?.()
  }, [canvas, video, height, width, additionalVideoRefs, onVideoStartCallback])

  useLayoutEffect(() => {
    if (isElementReady) {
      video.onpause = () => {
        cancelAnimationFrame(intervalRef.current)
        isVideoPlaying.current = false
      }
      video.onended = () => {
        cancelAnimationFrame(intervalRef.current)
        onVideoVideoEnd?.()
        isVideoPlaying.current = false
      }
      video.onplay = () => {
        onVideoStartCallback?.()
      }
    }
  }, [isElementReady, intervalRef, onVideoStartCallback, onVideoVideoEnd, video])

  const refeshVideo = useCallback(
    (videoRefExternal, canvasRefExternal) => {
      if (isElementReady) return

      video = videoRefExternal?.current
      canvas = canvasRefExternal?.current
      isElementReady = video && canvas
      if (isElementReady) {
        video?.play()
        video.onpause = () => {
          cancelAnimationFrame(intervalRef.current)
          isVideoPlaying.current = false
        }
        video.onended = () => {
          cancelAnimationFrame(intervalRef.current)
          onVideoVideoEnd?.()
          isVideoPlaying.current = false
        }
        video.onplay = () => {
          onVideoStartCallback?.()
        }
      }
    },
    [canvas, video, height, width, additionalVideoRefs, onVideoStartCallback],
  )

  return { drawImage, isVideoPlaying, refeshVideo }
}
