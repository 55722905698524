import { BASE_FUNNEARN_ENDPOINT } from 'config/constants/endpoints'
import {
  ApiGameByTypeResponse,
  GameConfig,
  GameRecord,
  GameRewardConfig,
  GameSummaryInfo,
  GameType,
  GameUserActivityResponse,
  LeaderBoardDataSum,
  SortBy,
  TokenInfo,
} from './types'

/**
 * API HELPERS
 */

const options = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Origin: `${process.env.NEXT_PUBLIC_FRONT_URL}`,
  },
}

/**
 * Fetch game data from API
 * input gameType: 99 - featured, 1 - new, 0 - coming release
 * @returns
 */
export const getGameByType = async (
  gameType: GameType,
  page: number,
  limit: number,
  sortBy: SortBy,
): Promise<ApiGameByTypeResponse> => {
  const res = await fetch(
    `${BASE_FUNNEARN_ENDPOINT}/getGameByType/page=${page}&limit=${limit}&gameType=${gameType}&sortBy=${sortBy}`,
  )
  if (res.ok) {
    const json = await res.json()
    if (json.code === 0) {
      return json.data
    }
    console.error('Failed to fetch Game By Type', json.code, json.message, gameType)
    return {} as ApiGameByTypeResponse
  }
  console.error('Failed to fetch Game By Type', res.statusText, gameType)
  return {} as ApiGameByTypeResponse
}

/**
 * Fetch game record from API
 * @returns
 */
export const getGameRecord = async (gameId: string): Promise<GameRecord> => {
  const res = await fetch(`${BASE_FUNNEARN_ENDPOINT}/getGameRecord/${gameId}`, options)
  if (res?.ok) {
    const json = await res.json()
    if (json.code === 0) {
      return json.data
    }
    console.error('Failed to fetch Game Record', gameId)
    return {} as GameRecord
  }
  console.error('Failed to fetch Game Record', gameId)
  return {} as GameRecord
}

export const getGameConfig = async (gameId: string): Promise<GameConfig> => {
  const res = await fetch(`${BASE_FUNNEARN_ENDPOINT}/getGameConfig/${gameId}`, options)
  if (res?.ok) {
    const json = await res.json()
    if (json.code === 0) {
      return json.data
    }
    return {} as GameConfig
  }
  return {} as GameConfig
}

export const getGameSummaryInfo = async (gameId: string): Promise<GameSummaryInfo> => {
  const res = await fetch(`${BASE_FUNNEARN_ENDPOINT}/getGameSummaryInfo/${gameId}`, options)
  if (res?.ok) {
    const json = await res.json()
    if (json.code === 0) {
      return json
    }
    return {} as GameSummaryInfo
  }
  return {} as GameSummaryInfo
}

export const getGameRewardConfig = async (gameId: string): Promise<GameRewardConfig[]> => {
  const res = await fetch(`${BASE_FUNNEARN_ENDPOINT}/getGameRewardConfig/${gameId}`, options)
  if (res?.ok) {
    const json = await res.json()
    if (json.code === 0) {
      return json.data
    }
    return [] as GameRewardConfig[]
  }
  return [] as GameRewardConfig[]
}

export const getGameLeaderboard = async (gameId: string, page: number, limit: number): Promise<LeaderBoardDataSum> => {
  const res = await fetch(
    `${BASE_FUNNEARN_ENDPOINT}/getGameLeaderBoard/gameId=${gameId}&page=${page}&limit=${limit}`,
    options,
  )
  if (res?.ok) {
    const json = await res.json()
    if (json.code === 0) {
      return json.data
    }
    console.error('getGameLeaderboard e', gameId, page, limit)
    return {} as LeaderBoardDataSum
  }
  console.error('getGameLeaderboard e', gameId, page, limit)
  return {} as LeaderBoardDataSum
}

export const getLiveTournament = async (page: number, limit: number): Promise<GameConfig[]> => {
  const res = await fetch(`${BASE_FUNNEARN_ENDPOINT}/getLiveTournament/page=${page}&limit=${limit}`)
  if (res.ok) {
    const json = await res.json()
    if (json.code === 0) {
      const gameRecords = json.data.docs
      const gameConfigs = await fetchGameConfig(gameRecords)
      const leaderBoardData = await fetchGameLeaderboard(gameRecords)
      const combineData = gameRecords.map((game, index) => {
        return {
          ...gameConfigs[index],
          leaderboardData: leaderBoardData[index] ? leaderBoardData[index] : {},
          gameRecord: game,
        } as GameConfig
      })

      return combineData
    }
    console.error('Failed to fetch tournament', json.code, json.message)
    return [] as GameConfig[]
  }
  console.error('Failed to fetch Game By Type')
  return [] as GameConfig[]
}

export const getLiveTournamentRespone = async (page: number, limit: number): Promise<ApiGameByTypeResponse> => {
  const res = await fetch(`${BASE_FUNNEARN_ENDPOINT}/getLiveTournament/page=${page}&limit=${limit}`)
  if (res.ok) {
    const json = await res.json()
    if (json.code === 0) {
      const gameRecords = json.data.docs
      const gameConfigs = await fetchGameConfig(gameRecords)
      const leaderBoardData = await fetchGameLeaderboard(gameRecords)
      const combineData = gameRecords.map((game, index) => {
        return {
          ...gameConfigs[index],
          leaderboardData: leaderBoardData[index],
          gameRecord: game,
        } as GameConfig
      })
      json.data.docs = combineData

      return json.data
    }
    console.error('Failed to fetch tournament', json.code, json.message)
    return {} as ApiGameByTypeResponse
  }
  console.error('Failed to fetch Game By Type')
  return {} as ApiGameByTypeResponse
}

export const getGameAllInfo = async (gameId: string): Promise<GameConfig> => {
  try {
    const [gameConfig, gameRecord, leaderboard, summaryInfo] = await Promise.all([
      getGameConfig(gameId),
      getGameRecord(gameId),
      getGameLeaderboard(gameId, 1, 100),
      getGameSummaryInfo(gameId),
    ])
    const sumData = gameConfig
    gameConfig.gameRecord = gameRecord
    gameConfig.leaderboardData = leaderboard !== undefined ? leaderboard : ({} as LeaderBoardDataSum)
    gameConfig.summaryInfo = summaryInfo
    return sumData
  } catch (error) {
    console.error('Unable to fetch data:', error)
    return {} as GameConfig
  }
}

/**
 * Fetch game record from API
 * @returns
 */
export const getTokenPrice = async (tokenAddress: string): Promise<number> => {
  const tokenInfoAPI = `https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`
  const res = await fetch(tokenInfoAPI)
  if (res?.ok) {
    const data = await res.json()
    console.log('getTokenPrice', tokenAddress, data)

    const pairData = data.pairs?.find(
      (p) =>
        p.quoteToken.symbol === 'USDT' ||
        p.quoteToken.symbol === 'BUSD' ||
        p.quoteToken.symbol === 'USDC' ||
        p.quoteToken.symbol === 'WBNB' ||
        p.quoteToken.symbol === 'WETH',
    )
    if (pairData) {
      return pairData.priceUsd
    }
    return 0
  }
  console.error('getTokenPrice err', tokenAddress)
  return 0
}

/**
 * Fetch token info from API
 * @returns
 */
export const getTokenInfo = async (tokenAddress: string): Promise<TokenInfo> => {
  const res = await fetch(`${BASE_FUNNEARN_ENDPOINT}/getTokenInfo/${tokenAddress}`)
  if (res.ok) {
    const json = await res.json()
    if (json.code === 0) {
      return json.data
    }
    console.error('Failed to fetch Token info', tokenAddress)
    return {} as TokenInfo
  }
  console.error('Failed to fetch Token info', tokenAddress)
  return {} as TokenInfo
}

export const getHomeGames = async (): Promise<Record<string, GameConfig[]>> => {
  try {
    const [featuredGame, newGame, upcomingGame] = await Promise.all([
      getGameByType(GameType.FEATURED, 1, 10, SortBy.HIGHPOOL),
      getGameByType(GameType.NEW, 1, 10, SortBy.NEW),
      getGameByType(GameType.COMING, 1, 10, SortBy.NEW),
    ])

    const featureGameReward = await fetchGameRecord(featuredGame.docs)
    const featureGameCombineData = featuredGame.docs.map((game, index) => {
      return {
        ...game,
        gameRecord: featureGameReward[index],
      } as GameConfig
    })

    const newGameReward = await fetchGameRecord(newGame.docs)
    const newGameGameCombineData = newGame.docs.map((game, index) => {
      return {
        ...game,
        gameRecord: newGameReward[index],
      }
    })

    return {
      featuredGame: featureGameCombineData,
      newGame: newGameGameCombineData,
      upcomingGame: upcomingGame.docs,
    }
  } catch (error) {
    console.error('Unable to fetch data:', error)
    return {}
  }
}

export const getAllGame = async (page: number, limit: number, sortBy: SortBy): Promise<GameConfig[]> => {
  try {
    const [allGame] = await Promise.all([getGameByType(GameType.ALL, page, limit, sortBy)])

    const gameRecord = await fetchGameRecord(allGame.docs)
    const tokenPrice = await fetchGameConfigTokenValue(allGame.docs)
    const gameDataCombine = allGame.docs.map((game, index) => {
      return {
        ...game,
        gameRecord: gameRecord[index],
        rewardPool: gameRecord[index].currentLeaderboardPool + gameRecord[index].currentAdsPool,
        rewardValue: (gameRecord[index].currentLeaderboardPool + gameRecord[index].currentAdsPool) * tokenPrice[index],
      } as GameConfig
    })

    return gameDataCombine
  } catch (error) {
    console.error('Unable to fetch data:', error)
    return []
  }
}

export const getAllGameRespone = async (
  page: number,
  limit: number,
  sortBy: SortBy,
): Promise<ApiGameByTypeResponse> => {
  try {
    const [allGame] = await Promise.all([getGameByType(GameType.ALL, page, limit, sortBy)])

    const gameRecord = await fetchGameRecord(allGame.docs)
    const tokenPrice = await fetchGameConfigTokenValue(allGame.docs)
    const gameDataCombine = allGame.docs.map((game, index) => {
      return {
        ...game,
        gameRecord: gameRecord[index],
        rewardPool: gameRecord[index].currentLeaderboardPool + gameRecord[index].currentAdsPool,
        rewardValue: (gameRecord[index].currentLeaderboardPool + gameRecord[index].currentAdsPool) * tokenPrice[index],
      } as GameConfig
    })

    return { ...allGame, docs: gameDataCombine }
  } catch (error) {
    console.error('Unable to fetch data:', error)
    return {} as ApiGameByTypeResponse
  }
}

export const getGameUserActivity = async (
  gameId: string,
  page: number,
  limit: number,
): Promise<GameUserActivityResponse> => {
  const res = await fetch(`${BASE_FUNNEARN_ENDPOINT}/getGameUserActivity/page=${page}&limit=${limit}&gameId=${gameId}`)
  if (res.ok) {
    const json = await res.json()
    if (json.code === 0) {
      return json.data
    }
    console.error('Failed to fetch getGameUserActivity', json.code, json.message, gameId)
    return {} as GameUserActivityResponse
  }
  console.error('Failed to fetch getGameUserActivity', res.statusText, gameId)
  return {} as GameUserActivityResponse
}

const fetchGameRecord = async (games: GameConfig[]): Promise<GameRecord[]> => {
  const totalGamePoolCall = games.map((game) => getGameRecord(game.gameId))
  if (totalGamePoolCall.length > 0) {
    const totalRewardData = await Promise.all(totalGamePoolCall)
    return totalRewardData
  }
  return []
}

const fetchGameConfig = async (games: GameRecord[]): Promise<GameConfig[]> => {
  const totalGamePoolCall = games.map((game) => getGameConfig(game.gameId))
  if (totalGamePoolCall.length > 0) {
    const totalRewardData = await Promise.all(totalGamePoolCall)
    return totalRewardData
  }
  return []
}

const fetchGameLeaderboard = async (games: GameRecord[]): Promise<LeaderBoardDataSum[]> => {
  const totalGamePoolCall = games.map((game) => getGameLeaderboard(game.gameId, 1, 100))
  if (totalGamePoolCall.length > 0) {
    const totalRewardData = await Promise.all(totalGamePoolCall)
    return totalRewardData
  }
  return []
}

const fetchGameConfigTokenValue = async (games: GameConfig[]): Promise<number[]> => {
  const totalFetchCall = games.map((game) => getTokenPrice(game.withdrawToken))
  if (totalFetchCall.length > 0) {
    const totalRewardData = await Promise.all(totalFetchCall)
    return totalRewardData
  }
  return []
}
