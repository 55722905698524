// Collections -> Nfts -> Transactions
// Users -> Nft tokens IDs

export enum GameType {
  COMING = 0,
  NEW = 1,
  FEATURED = 99,
  ALL = -1,
}

export enum SortBy {
  NEW = 1,
  OLD = 2,
  HIGHPOOL = 3,
  LOWPOOL = 4,
}

export interface GameConfig {
  bannerURL: string
  gameIcon: string
  gameId: string
  gameName: string
  gameDescription: string
  totalUser: number
  tokenIcon?: string
  tokenSymbol?: string
  tokenPool?: number
  rewardDistributeTime: Date
  androidStore?: string
  iosStore?: string
  playLink?: string
  withdrawToken: string
  withdrawFee: [
    {
      min: number
      max: number
      percentFee: number
    },
  ]
  withdrawLimitDay: number
  limitWithdraw: number
  minWithdraw: number
  gameType: number
  gameRecord: GameRecord
  createAt: string
  rewardPool: number
  rewardValue: number
  leaderboardData: LeaderBoardDataSum
  summaryInfo: GameSummaryInfo
  scrollBanner: string[]
}

export interface GameSummaryInfo {
  totalPlayTime: number
  totalLeaderboardDistributed: number
  totalGameActivityDistributed: number
}

export interface ApiGameByTypeResponse {
  totalDocs: number
  totalPages: number
  page: number
  limit: number
  docs: GameConfig[]
}

export interface GameRecord {
  gameId: string
  currentSeason: number
  currentLeaderboardPool: number
  currentAdsPool: number
  gameStatus: number
  lastStartSeasonTime: Date
  lastEndSeasonTime: Date
  lastDistributeRewardTime: Date
  currentTotalAdsUnitReward: number
}

export interface GameRewardConfig {
  gameId: string
  rankUpper: number
  rankLower: number
  sharePercent: number
}

export interface TokenInfo {
  address: string
  chain: number
  chainName: string
  name: string
  iconURL: string
  priceUSD: string
  decimal: number
}

export interface LeaderBoardData {
  userId: string
  userName: string
  value: number
  rank: number
  metadata: string
}

export interface LeaderBoardRespone {
  code: number
  data: LeaderBoardDataSum
}

export interface LeaderBoardDataSum {
  scores: LeaderBoardData[]
  maxRange: number
}

export interface GameUserData {
  email: string
  gameId: string
  userID: string
  currentECoinEarn: number
  totalECoinEarned: number
  playTime: number
  lastPlayTime: Date
  currentAdsUnit: number
  lastWithdrawTokenDate: Date
}

export interface GameUserReward {
  leaderBoardReward: number
  playReward: number
}

export interface GameUserActivity {
  gameId: string
  userID: string
  action: string
  metadata: string
  logDate: Date
}

export interface GameUserActivityResponse {
  totalDocs: number
  totalPages: number
  page: number
  limit: number
  docs: GameUserActivity[]
}

export interface ClaimRecord {
  claimId: string
  poolId: string
  address: string
  amount: number
  email: string
  status: number
  gameId: string
  createAt: Date
  updateAt: Date
}

export interface ClaimLog {
  gameId: string
  claimId: string
  mekleRoot: string
  fundData: [
    {
      address: string
      amout: number
    },
  ]
  pooId: string
  updateAt: Date
}

export interface WithdrawRecord {
  _id: string
  gameId: string
  address: string
  email: string
  userID: string
  tokenAmount: number
  tokenAddress: string
  requestTimestamp: Date
  status: number
  feeAmount: number
  updateAt: Date
  poolId: number
}

export interface WithdrawRecordRespone {
  totalDocs: number
  totalPages: number
  page: number
  limit: number
  docs: WithdrawRecord[]
}

export interface UserEarnData {
  leaderBoardReward: number
  playReward: number
  rankData: LeaderBoardData
}

export interface UserInfo {
  userID: string
  name: string
  email: string
  walletAddress: string
}

export interface ListGameRecordRespone {
  totalDocs: number
  totalPages: number
  page: number
  limit: number
  docs: GameRecord[]
}

export interface LiveTournamentRespone {
  gameRecords: ListGameRecordRespone
  leaderBoardData: LeaderBoardDataSum[]
}
