import { useTranslation } from '@pancakeswap/localization'
import { Box, Flex, Image, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { memo } from 'react'
import { keyframes, styled } from 'styled-components'
import * as S from './Styled'

const flyingAnim = keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-5px, 5px);
  }
  to {
    transform: translate(0, 0px);
  }
`

// const pancakeSwapLogo = `${ASSET_CDN}/web/banners/ethXpancakeswap.png`
const funnearnLogo = `/logoWithText.png`

const RightWrapper = styled.div`
  position: absolute;
  min-height: 100%;
  width: 100%;
  right: 0;
  bottom: -2px;
  z-index: 2;

  > span:first-child {
    position: absolute !important;
    top: -20px;
    left: 70%;
    z-index: 3;
    animation: ${flyingAnim} 2.5s ease-in-out infinite;

    ${({ theme }) => theme.mediaQueries.lg} {
      left: 58%;
    }
  }

  > span:nth-child(2) {
    position: absolute !important;
    right: 0;
    bottom: 2px;
    z-index: 2;

    ${({ theme }) => theme.mediaQueries.lg} {
      right: 8%;
    }
  }
`

const BgWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: -2px;
  overflow: hidden;
  height: 100%;
  border-radius: 32px;
  z-index: 1;

  > span:first-child {
    position: relative !important;
    right: 0px;
    top: 0px;
    height: 100% !important;
  }
`
const Header = styled.div`
  padding-right: 100px;
  position: relative;
  color:white;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 98%;
  letter-spacing: 0.01em;
  font-feature-settings: 'liga' off;
 
  margin-left: 3px;


  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: 33px;
  }
`

const GradientText = styled(Text)<{ backgroundColor?: string }>`
  font-size: 19.823px;
  font-weight: 800;
  line-height: 105%;
  letter-spacing: 0.496px;
  background-clip: text;
  background: ${({ backgroundColor }) => backgroundColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const GameBanner = () => {
  const { t, currentLanguage } = useTranslation()
  const { isMobile, isDesktop } = useMatchBreakpoints()

  return (
    <S.Wrapper
      style={{
        background: `linear-gradient(140deg, rgb(5 169 0) 0%, rgb(87 186 38) 100%)`,
      }}
    >
      <S.Inner>
        <S.LeftWrapper position="relative" style={{ zIndex: 3, justifyContent: isMobile ? 'flex-start' : 'center' }}>
          <Flex alignItems="center" mb="8px" style={{ gap: isMobile ? 8 : 10 }}>
            <Image
              src={funnearnLogo}
              alt="logo"
              width={isMobile ? 100 : 150}
              height={isMobile ? 30 : 60}
            />
          </Flex>
          <Header data-text={t('Beta Launching')}>
            {isDesktop ? t('Beta Launching 🚀🚀🚀🚀🚀') : t('Beta Launching')}
          </Header>
          <Box>
            {isDesktop ? (
              <Flex mt="8px">
                <Text color='black'>
                  {t('funnearn.gg start beta launching on 1st of January 2024')}
                </Text>

              </Flex>
            ) : (
              <Flex mt="8px">
              <Text color='black'>
                {t('funnearn.gg start beta launching on 1st of January 2024')}
              </Text>

            </Flex>
            )}
          </Box>
          {/* <Link style={{ textDecoration: 'none' }} external href="https://pancakeswap.games/">
            <Button variant="text" pl="0px" pt="0px" scale={isMobile ? 'sm' : 'md'}>
              <Text textTransform={isMobile ? 'uppercase' : 'capitalize'} bold fontSize="16px" color="#05FFC3">
                {t('Try Now')}
              </Text>
              <OpenNewIcon color="#05FFC3" />
            </Button>
          </Link> */}
        </S.LeftWrapper>
        {/* <RightWrapper>
          <Image src={gameCube} alt="gameCube" width={52} height={52} placeholder="blur" />
          {isDesktop ? (
            <Image src={gameDesktopBunny} alt="gameDesktopBunny" width={264.5} height={192} placeholder="blur" />
          ) : (
            <Image src={gameMobileBunny} alt="gameMobileBunny" width={338} height={176} placeholder="blur" />
          )}
          <BgWrapper>
            {isDesktop && <Image src={gameDesktopBg} alt="gameBg" width={1126} height={192} placeholder="blur" />}
          </BgWrapper>
        </RightWrapper> */}
      </S.Inner>
    </S.Wrapper>
  )
}

export default memo(GameBanner)
