import { TFetchStatus } from 'config/constants/types'
import useSWR from 'swr'

import {
  getAllGameRespone,
  getGameAllInfo,
  getGameConfig,
  getGameLeaderboard,
  getGameRewardConfig,
  getGameUserActivity,
  getHomeGames,
  getLiveTournamentRespone,
  getTokenInfo,
  getTokenPrice,
} from './helpers'
import {
  ApiGameByTypeResponse,
  GameConfig,
  GameRewardConfig,
  GameUserActivityResponse,
  LeaderBoardData,
  SortBy,
  TokenInfo,
} from './types'

const DEFAULT_NFT_ORDERING = { field: 'currentAskPrice', direction: 'asc' as 'asc' | 'desc' }
const DEFAULT_NFT_ACTIVITY_FILTER = { typeFilters: [], collectionFilters: [] }
const EMPTY_OBJECT = {}

export const useGetHomeGame = (): { data: Record<string, GameConfig[]>; status: TFetchStatus } => {
  const { data, status } = useSWR('homeGame', async () => getHomeGames())
  const games = data ?? ({} as Record<string, GameConfig[]>)
  return { data: games, status }
}

export const useGetAllGame = (
  page: number,
  limit: number,
  sortBy: SortBy,
): { data: ApiGameByTypeResponse; status: TFetchStatus } => {
  const { data, status } = useSWR(['allGame', page, limit], async () => getAllGameRespone(page, limit, sortBy))
  const games = data ?? ({} as ApiGameByTypeResponse)
  return { data: games, status }
}

export const useGetTokenInfo = (tokenAddress: string): { data: TokenInfo; status: TFetchStatus } => {
  const { data, status } = useSWR(`tokenInfo/${tokenAddress}`, async () => getTokenInfo(tokenAddress))
  const tokenInfo = data ?? ({} as TokenInfo)
  return { data: tokenInfo, status }
}

export const useGetTokenPrice = (tokenAddress: string): { data: number; status: TFetchStatus } => {
  const { data, status } = useSWR(`tokenPrice/${tokenAddress}`, async () => getTokenPrice(tokenAddress))
  const tokenPrice = data ?? 0
  return { data: tokenPrice, status }
}

export const useGetGameInfo = (gameId: string): { data: GameConfig; status: TFetchStatus } => {
  const { data, status } = useSWR(`gameInfo/${gameId}`, async () => getGameAllInfo(gameId))

  const gameData = data ?? ({} as GameConfig)
  return { data: gameData, status }
}

export const useGetGameConfig = (gameId: string): GameConfig => {
  const { data } = useSWR(`gameConfig/${gameId}`, async () => getGameConfig(gameId))
  const gameData = data ?? ({} as GameConfig)
  return gameData
}

export const useGetGameRewardConfig = (gameId: string): GameRewardConfig[] => {
  const { data } = useSWR(`gameRewardConfig/${gameId}`, async () => getGameRewardConfig(gameId))
  const gameRewardConfig = data ?? ([] as GameRewardConfig[])
  return gameRewardConfig
}

export const useGetGameLeaderboard = (
  gameId: string,
  page: number,
  limit: number,
): { data: LeaderBoardData[]; status: TFetchStatus } => {
  const { data, status } = useSWR(`getGameLeaderBoard/${gameId}/${page}/${limit}`, async () =>
    getGameLeaderboard(gameId, page, limit),
  )
  const leaderBoardSum = data ?? { scores: [], maxRange: 0 }
  return { data: leaderBoardSum.scores, status }
}

export const useGetLiveTournament = (
  page: number,
  limit: number,
): { data: ApiGameByTypeResponse; status: TFetchStatus } => {
  const { data, status } = useSWR(['liveTournament', page, limit], async () => getLiveTournamentRespone(page, limit))

  const games = data ?? ({} as ApiGameByTypeResponse)
  return { data: games, status }
}

export const useGetGameUserActivity = (
  gameId: string,
  page: number,
  limit: number,
): { data: GameUserActivityResponse; status: TFetchStatus } => {
  const { data, status } = useSWR(['gameUserActivity', gameId, page, limit], async () =>
    getGameUserActivity(gameId, page, limit),
  )

  const games = data ?? ({} as GameUserActivityResponse)
  return { data: games, status }
}
